import { Routes, Route, BrowserRouter } from "react-router-dom";
import App from "../pages/App";
import NewPage from "../pages/NewPage";
import ListPages from "../pages/ListPages";
import EditPage from "../pages/EditPage";
import { useState, useEffect } from "react";
import { getPages } from "../utils/pages";

export default function Router() {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    let localPages = localStorage.getItem("page_list");
    if (typeof localPages != "string") {
      getPages().then((res) => {
        localPages = res;
      });
    }
    setPages(JSON.parse(localPages));
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/new" element={<NewPage />} />
        <Route path="/list" element={<ListPages pages={pages} />} />
        {typeof pages == typeof []
          ? pages.map((page) => (
              <Route
                key={page.id}
                path={`/edit/${page.id}`}
                element={<EditPage id={page.id} />}
              />
            ))
          : null}
      </Routes>
    </BrowserRouter>
  );
}
