import EditorForm from '../components/EditorForm';
import {Link} from "react-router-dom";

export default function NewPage() {
  return (
    <div className="App">
      <h1>New Page</h1>
      <div>
        <Link to="/">Back</Link>
      </div>
      <EditorForm newPage={true} />
    </div>
  );
}