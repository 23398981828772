import { useEffect } from "react";
import { basicSetup, EditorView } from "codemirror";
import { htmlLanguage } from "@codemirror/lang-html";
import PropTypes from "prop-types";

// CodeMirror reference
// https://codemirror.net/

export default function Editor(props) {
  useEffect(() => {
    new EditorView({
      doc: props.content ? `${props.content}` : "<h1>Hello, World</h1>",
      extensions: [basicSetup, htmlLanguage.extension],
      parent: document.getElementById("editor"),
    });
    // eslint-disable-next-line
  }, []);
  return <div id="editor"/>;
}

Editor.propTypes = {
  content: PropTypes.string,
};
