import { useEffect, useState } from "react";
import EditorForm from "../components/EditorForm";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getPageById } from "../utils/pages";

export default function EditPage(props) {
  const [page, setPage] = useState();
  useEffect(() => {
    getPageById(props.id).then((res) => {
      setPage(res);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <h1>Edit Page</h1>
      <div>
        <Link to="/list">Back</Link>
      </div>
      {page ? (
        <EditorForm newPage={false} content={page.content} page={page} />
      ) : null}
    </div>
  );
}

EditPage.propTypes = {
  id: PropTypes.number,
};
