export async function getPageById(id) {
    const res = await fetch(`https://api.reactcms2023.viewmynew.com/page/${id}`)
    const jres = await res.json()
    console.log(JSON.stringify(jres));
    return jres;
}

export async function getPages() {
    const res = await fetch("https://api.reactcms2023.viewmynew.com/page")
    const jres = await res.json()
    console.log(JSON.stringify(jres));
    localStorage.setItem("page_list", JSON.stringify(jres));
    return JSON.stringify(jres);
}