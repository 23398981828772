import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getPages } from "../utils/pages";
import { Link } from "react-router-dom";

export default function ListPages(props) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (typeof window != "undefined") {
      setTimeout(() => {
        const localPages = localStorage.getItem("page_list");
        if (localPages) {
          setPages(JSON.parse(localPages));
        }
      }, 200);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <h1>Page List</h1>
      <div>
        <Link to="/">Back</Link>
      </div>
      <button
        type="button"
        onClick={() => {
          localStorage.removeItem("page_list");
          getPages().then((res) => {
            localStorage.setItem("page_list", res);
            setPages(JSON.parse(localStorage.getItem("page_list")))
          });
        }}
      >
        Refresh
      </button>
      {pages.map((page, index) => {
        return (
          <div key={index}>
            <Link to={`/edit/${page.id}`}>{page.title}</Link>
          </div>
        );
      })}
    </>
  );
}

ListPages.propTypes = {
  pages: PropTypes.array,
};
