import "../styles/App.css";
import { Link } from "react-router-dom";

export default function App() {
  return (
    <div className="App">
      <h1>App</h1>
      <Link to="/new">New Page</Link>
      <br />
      <Link to="/list">List Pages</Link>
    </div>
  );
}