import Editor from "./Editor";
import PropTypes from "prop-types";
import { useState } from "react";
import { Navigate } from "react-router-dom";

// CodeMirror reference
// https://codemirror.net/

export default function EditorForm(props) {
  const [redirect, setRedirect] = useState(false);
  function handlePageReq(event) {
    event.preventDefault();
    const newPage = {
      title: event.target.title.value,
      short_description: event.target.short_description.value,
      description: event.target.description.value,
      content: document.querySelector(".cm-content").innerText,
      live: true,
      url: event.target.route.value,
      seo_tags: event.target.seo_tags.value,
    };
    fetch(
      `https://api.reactcms2023.viewmynew.com/page${
        props.page ? `/${props.page.id}` : ""
      }`,
      {
        mode: "cors",
        method: props.newPage ? "post" : "put",
        body: JSON.stringify(newPage),
        headers: { "content-type": "application/json" },
      }
    )
      .then((res) => {
        console.log(res);
        if (props.newPage) {
          setRedirect(true);
        } else {
          window.alert("Page updated");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      {!redirect ? (
        <div className="Editor">
          <form onSubmit={handlePageReq}>
            <label htmlFor="title">Title</label>
            <input
              name="title"
              id="title"
              type="text"
              defaultValue={props.page ? props.page.title : ""}
              required
            />
            <br />
            <label htmlFor="route">Route</label>
            <input
              name="route"
              id="route"
              type="text"
              defaultValue={props.page ? props.page.url : ""}
              required
            />
            <br />
            <label htmlFor="short_description">Short Description</label>
            <input
              name="short_description"
              id="short_description"
              type="text"
              defaultValue={props.page ? props.page.short_description : ""}
              required
            />
            <br />
            <label htmlFor="description">Description</label>
            <input
              name="description"
              id="description"
              type="text"
              defaultValue={props.page ? props.page.description : ""}
              required
            />
            <br />
            <label htmlFor="seo_tags">SEO Tags</label>
            <input
              name="seo_tags"
              id="seo_tags"
              type="text"
              defaultValue={props.page ? props.page.seo_tags : ""}
              required
            />
            <br />
            <Editor content={props.content ? props.content : null} />
            <button>{props.newPage ? "new page" : "edit page"}</button>
          </form>
        </div>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
}

Editor.propTypes = {
  newPage: PropTypes.bool,
  content: PropTypes.string,
  page: PropTypes.object,
};
